<template>
  <v-expansion-panels v-model="panelYandexMarket" class="mt-2" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header class="title">
        <div>
          <v-icon v-if="isActive===true" small color="success">
            mdi-check-circle
          </v-icon>
          <v-icon v-else small color="warning">
            mdi-pause-circle
          </v-icon>
          Yandex
          <v-avatar size="18">
            <v-img :src="require('@/assets/images/ym_96.png')"/>
          </v-avatar>
          Market
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="text-body-2">
        <v-card>
          <v-card-text>
            <v-switch
              v-model="isActiveData"
              dense
              label="Статус"
              hint="Если активно, то товар будет участвовать в экспорте"
              persistent-hint
              :disabled="disabled"
              class="mb-2"
              @change="$emit('toggle-activity',isActiveData)"
            />

            <text-line :value="ym.name" title="Название (name)"/>
            <text-line :value="ym.typePrefix" title="Товарный префикс (typePrefix)"/>
            <text-line :value="ym.vendor" title="Вендор (vendor)"/>
            <text-line :value="ym.model" title="Модель (model)"/>
            <text-line :value="ym.description" title="Описание (description)"/>
            <text-line :value="ym.shortDescription" title="Короткое описание (shortDescription)"/>

          </v-card-text>
          <v-card-actions v-can:access="{type:'acl', id:'Admin'}">
            <v-spacer></v-spacer>
            <v-btn @click="$router.back()">Назад</v-btn>
            <v-btn @click="navigateEditPage">Изменить</v-btn>
          </v-card-actions>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import TextLine from '@/components/ui/TextLine.vue'

export default {
  name: 'ItemYandexMarketCard',
  components: { TextLine },
  props: {
    id: {
      type: String,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    ym: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: function() {
        return false
      }
    }
  },
  data: function() {
    return {
      panelYandexMarket: [1],
      isActiveData: this.isActive
    }
  },
  methods: {
    navigateEditPage() {
      this.$router.push({ name: 'item.yandex_market.edit', param: { id: this.id } })
    }
  }
}
</script>
